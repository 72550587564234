html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "poppins";
  src: local("poppins"), url(poppins-v15-latin-regular.woff) format("woff");
}

body {
  margin: 0;
  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: block;
  width: 16px;
  background-color: #f1f1f1;
  border: 1px #f1f1f1;
  vertical-align: top; 
}

body::-webkit-scrollbar-thumb {
  display: block;
  background-color: #c1c1c1;
  vertical-align: top;

}

body {
  -ms-overflow-style: 12px; /* IE and Edge */
  scrollbar-width: 12px; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

svg {
  width: 140;
  height: 40px;
  fill: #ffffff;
}

svg:hover path {
  fill: #60efda;
  transition: ease-out 0.1s;
}


.button1 .button1 {
  width: 200px;
  height: 40px;
}

.butn {
  margin: 0 auto;
    display: block;
}
.footerBar {
  display: flex;
  max-width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to right, rgb(32, 37, 45), rgb(9, 9, 9), rgb(9, 9, 9));
  border-top: 1px solid grey;
}


.footerMenu {
  list-style-type: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menuItems {
  margin-right: 20px;
  font-size: 15px;
  text-transform: uppercase;
  color: #f1f1f1;
  cursor: pointer;
}

.footerMenuIcons {
  list-style-type: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.footerIcons {
  list-style-type: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menuIcons {
  margin-right: 30px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .footerBar {
    display: flex;
  }

  .footerMenu {
    display: none;
  }

  .menuItems {
    margin-right: 20px;
    font-size: 20px;
    text-transform: uppercase;
    color: #f1f1f1;
    cursor: pointer;
}

  .footerMenuIcons {
    display: flex;
  }
  
  .footerIcons {
    flex-direction: row;
  }

  a {
    color: #ffffff;
    text-decoration: none !important;
}

a:hover {
    color:none; 
    text-decoration:none; 
    cursor:pointer; 
}

a:visited {
  color: #ffffff;
  text-decoration: none !important;
}

a:link {
  color: #ffffff;
  text-decoration: none !important;
} 

}

/* SWIPER CSS*/


.swiper-container {
  width: 1170px;
  margin: auto auto;
  max-width: 90%;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 30;
  /* Fix of Webkit flickering */
  z-index: 99;
}

.demo-section__arrows {
  width: 1170px;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline;
  background: transparent;
}


.swiper-button-prev {
  position: absolute;
  left: 1rem;
  -webkit-calc: (50% - 3rem);
  top: calc(50% - 1rem);
  width: 5rem;
  height: 5rem;
  background: hsla(0,0%,100%,.4);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-position-x: -3rem;
  border-radius: 50%;
  z-index: 99;
  -webkit-transition: background-color .1s;
  transition: background-color .1s;
  transition-property: background-color;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay: 0s;
  color: rgb(255, 255, 255);
 
}


.swiper-button-next {
  position: absolute;
  right: 1rem;
  -webkit-calc: (50% - 3rem);
  top: calc(50% - 1rem);
  width: 5rem;
  height: 5rem;
  background: hsla(0,0%,100%,.4);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-position-x: -3rem;
  border-radius: 50%;
  z-index: 99;
  -webkit-transition: background-color .1s;
  transition: background-color .1s;
  transition-property: background-color;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay: 0s;
  color: rgb(255, 255, 255);

}


.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px;
  z-index: 1;
  display: flex;
  /*transition-property: transform;
  box-sizing: content-box; */
}


li:hover {
  cursor: pointer;
  -webkit-transition:color 0.1s ease-in;
  -moz-transition:color 0.1s ease-in;
    color:#60efda;  
}

.mintText {
  background: linear-gradient(to right, rgb(236, 72, 153), rgb(239, 68, 68), rgb(245, 158, 11));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.metamaskButton {
  -webkit-filter: blur(1.5px);
  filter: blur(1.5px);
  opacity: 0.3;
}