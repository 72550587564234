.navContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(to right, rgb(236, 72, 153), rgb(239, 68, 68), rgb(245, 158, 11));

}

.navInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1170px;
}

.navLogo {
    width: 150px;
    cursor: pointer;
}

.navLogoMobile {
    display: none;
    }

.Navig {
    height: 80px;
    width: 100%;
    position: relative;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navSocial {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
}


nav ul li {
    display: inline-block;
    margin: 1.5vw;
}

nav ul li /* a */ {
    text-decoration: none;
    color: white;
}

.social {
    width:20px; 
}


.MobileNavig {
    display: none;
}

@media (max-width: 767px){
.navLogo {
    display: none;
    }

.navLogoMobile {
    display: flex;
    margin-right:50%;
    margin-left: 5%; 
    width: 300px;
    }

.navSocial {
    display: none;
    }

.Navig {
    display: none;
    }

.MobileNavig {
    display: flex;
    align-items: center;
    height: 80px;
    background: linear-gradient(to right, rgb(236, 72, 153), rgb(239, 68, 68), rgb(245, 158, 11));
    cursor: pointer;

    }

.Hamburger {
    position: absolute;
    right: 3%;
    cursor: pointer;

}    

.Close {
    position: absolute;
    right: 3%;
    cursor: pointer;

}    

nav ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 80px;
    right: 0;
    background: conic-gradient(at left center, rgb(244, 63, 94), rgb(67, 56, 202));
    z-index: 99;
}

nav ul li {
    padding-left: 4%;
    padding-top: 10px;
}

}